<template>
    <section>
        <div class="resume-card">
            <div class="resume-header">
                <div class="resume-title">Resultado por período</div>
                <div class="filter-wrapper">
                    <Loading :class="{ icon: true, reload: true, loading: loading }"
                        @click="() => !loading && getPendingHistoryResume()" />

                        <Periods @select="handlePeriod" :monthSelected="monthSelected" />
                </div>
            </div>
            <hr />
            <div class="values-wrapper">
                <div class="value-content">
                    <div class="value-title">Total faturado</div>
                    <div class="value">
                        <div class="blue">
                            {{ parseNumberToMoney(pendingHistoryResume?.invoiced_value?.value ?? 0) }}
                        </div>
                    </div>
                </div>
                <div class="vertical-divider" />

                <div class="value-content">
                    <div class="value-title">Total Pago</div>
                    <div class="value">
                        <div class="blue">
                            {{ parseNumberToMoney(pendingHistoryResume?.paid_value?.value ?? 0) }}
                        </div>

                        <div class="tag paid-value-color">
                            {{ parseInt(pendingHistoryResume?.paid_value?.diff ?? 0) }} %
                        </div>
                    </div>
                </div>
                <div class="vertical-divider" />

                <div class="value-content">
                    <div class="value-title">Total pendente</div>
                    <div class="value">
                        <div>
                            {{ parseNumberToMoney(pendingHistoryResume?.pending_value?.value ?? 0) }}
                        </div>
                        <div class="tag pending-value-color">
                            {{ parseInt(pendingHistoryResume?.pending_value?.diff ?? 0) }} %
                        </div>
                    </div>
                </div>
                <div class="vertical-divider" />

                <div class="value-content">
                    <div class="value-title">Total glosado/vencido</div>
                    <div class="value">
                        <div>{{ parseNumberToMoney(pendingHistoryResume?.expired_value?.value ?? 0) }}</div>
                        <div class="tag expired-value-color">
                            {{ parseInt(pendingHistoryResume?.expired_value?.diff ?? 0) }} %
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import api from '../api';
import { parseNumberToMoney } from '@/utils/moneyHelper';
import { normalizeProductNumber } from '@/utils/product-helper';
import { getCurrentClinic } from '@/utils/localStorageManager';

export default {
    components: {
        Loading: () => import('@/assets/icons/loading.svg'),
        Periods: () => import('@/components/General/Periods'),
    },
    mounted() {
        this.getPendingHistoryResume();
    },
    data() {
        return {
            loading: false,
            clinic: getCurrentClinic(),
            currentMonth: new Date(),
            pendingHistoryResume: null,
            langDatePicker: {
                formatLocale: {
                    weekdaysMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
                },
            },
            period: null,
            periodStatus: 'due_date',
            periodOrder: 'asc',
            start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
            monthSelected: true,

        };
    },
    methods: {
        parseNumberToMoney,
        normalizeProductNumber,
        async getPendingHistoryResume() {
            try {
                if (this.loading) return;
                this.loading = true;
    
                const { data } = await api.getPendingHistoryResume(this.clinic.id, this.start, this.end)

                this.pendingHistoryResume = data;
                    
            } catch (error) {
                console.error(error)
            } finally {
                this.loading = false;
            }
        },

    handlePeriod(value) {
      this.start = value.start
      this.end = value.end
    },

    },
    watch: {
        start() {
            this.getPendingHistoryResume();
        }
    }
};
</script>
<style lang="scss" scoped>
.resume-card {
    width: 100%;
    border: 1px solid var(--neutral-200);
    border-radius: 8px;
    padding: 1.5rem;
    margin-bottom: 2rem;

    .resume-header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .resume-title {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 1.1rem;
        }

        .filter-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;

            .icon {
                width: 48px;
            }

            .datepicker {
                .datepicker-chevrondown {
                    width: 20px;
                    stroke: var(--blue-500);
                }

                width: auto;

                .vx-input-wrapper .vx-input {
                    border: none !important;
                    height: auto !important;
                    color: var(--type-active) !important;
                }
            }
        }
    }

    .values-wrapper {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        overflow-y: auto;

        .value-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: start;
        }

        .value-title {
            color: var(--dark-blue);
            font-weight: 600;
            font-size: 0.8rem;
        }

        .value {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 0.5rem;
            font-weight: 700;
            font-size: 1.5rem;
            color: var(--dark-blue);

            .green {
                color: #58D19E;
            }

            .red {
                color: var(--states-error);
            }

            .tag {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 4px 8px;
                gap: 4px;
                font-family: 'Nunito Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 130%;
                border-radius: 16px;
                white-space: nowrap;

                &.green {
                    background-color: #58D19E;
                    color: var(--neutral-000);
                }

                &.red {
                    background-color: var(--states-error);
                    color: var(--neutral-000);
                }

                &.paid-value-color {
                    background-color: #DBFFF0;
                }

                &.pending-value-color {
                    background-color: #FFEBCC;
                }

                &.expired-value-color {
                    background-color: #FEE4E2;
                }


            }
        }
    }

    .vertical-divider {
        width: 1px;
        height: 70px;
        margin: 0 2rem;
        background-color: var(--neutral-200);
    }

}
</style>